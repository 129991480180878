/*
==================================================================
                        Post
==================================================================
*/

#newsfeed {
  .post {
    padding: 10px 0 0px 0;
    background: #fff;
    display: block;
    margin-bottom: 25px;
    flex-direction: column;
    &.pinned {
      background: rgba(0, 0, 0, 0.01);
      border: solid 1px #ccc;
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin: 10px 5px 10px 5px;
      position: relative;
      z-index: 6;
      .leftSide {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;

        .tinyimg,
        .img {
          width: auto;
          height: 40px;
          max-width: 40px;
          object-fit: cover;
        }
        .shortUserInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 10px;
          position: relative;
          h3 {
            margin: 0px;
            padding: 0px;
            font-size: 14px;
            word-break: break-word;
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          .timeago {
            font-size: 12px;
            color: var(--gray-color);
          }
          // .icons {
          // }
        }
      }
      .rightSide {
        display: flex;
        position: relative;
        button.link {
          margin-left: 10px;
          img {
            margin-left: 0px;
            cursor: pointer;
          }
          i {
            display: block;
            width: 20px;
            height: 20px;
            border: solid 1px --var(--primary-color);
            background: white;
            border-radius: 20px;
            font-style: normal;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
          }
        }
        button.link.comments {
          display: flex;
          i {
            display: block;
            width: 20px;
            height: 20px;
            border: solid 1px --var(--primary-color);
            background: white;
            border-radius: 20px;
            font-style: normal;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
    .readmorebtn {
      font-size: 14px;
      line-height: 18px;
      color: var(--primary-color);
      background: transparent;
      border: none;
      padding: 0px 0;
      margin-left: 18px;
      margin-bottom: 10px;
    }
    .cont {
      padding: 0 18px 10px 18px;
      display: block;
      word-break: break-word;
      p:last-child {
        margin-bottom: 0px;
      }
      blockquote {
        font-style: italic;
        display: block;
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-size: 12px;
        border-left: 5px solid #ccc;
        margin-bottom: 15px;
        &:after {
          clear: both;
          content: '';
          display: block;
          width: 100%;
          height: 1px;
        }
        p {
          padding-bottom: 15px;
        }
      }
      &.fullHeight {
        max-height: auto;
        padding-bottom: 10px;
      }
      &.fixedHeight {
        overflow: hidden;
        max-height: 205px;
        padding-bottom: 10px;
      }
      a {
        color: var(--primary-color);
      }
      * {
        max-width: 100%;
      }
    }
    .gallery {
      padding: 0 18px 10px 18px;
      display: flex;
      flex-direction: column;
      max-height: 90%;
      margin-top: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain !important;
      }
      .firstTile,
      .secondTile,
      .thirdTile {
        position: relative;
      }
      .mask {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);
        color: white;
        font-size: 50px;
        font-weight: 500;
        z-index: 5;
        &.higher {
          z-index: 10;
        }
      }
      &.tile {
        .firstTile {
          display: flex;
          height: 50%;
          flex: 1 1 auto;
          position: relative;
          overflow: hidden;

          img.pic {
            border-bottom: solid 1px white;
            width: 100%;
            height: auto;
            max-height: 560px;
            object-fit: contain;
          }
          .bgImage {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 100%;
            height: 100%;
            -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
            filter: blur(10px);
            object-fit: cover !important;
            max-height: 600px;
          }
        }

        .tileRow {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 460px;

          img,
          video {
            object-fit: cover;
          }
          video {
            width: 100%;
            height: 100%;
          }
          .thirdTile {
            border-left: solid 1px white;
          }
          .secondTile,
          .thirdTile {
            position: relative;
            display: flex;
            flex: 1;
            overflow: hidden;
            .pic {
              width: 100%;
              object-fit: contain;
            }
            .bgImage {
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: 1;
              width: 100%;
              height: 100%;
              -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
              filter: blur(10px);
              object-fit: cover !important;
            }
          }
        }
      }
      /* Verticle Tiled */
      &.vtile {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: start;
        max-height: 600px;
        .firstTile {
          display: flex;
          flex: 1;
          border-right: solid 2px white;
          position: relative;
          z-index: 2;
          overflow: hidden;
          img,
          video {
            object-fit: contain;
            width: 100%;
            height: auto;
            z-index: 2;
          }
          .bgImage {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 100%;
            height: 100%;
            -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
            filter: blur(10px);
            object-fit: cover !important;
            max-height: 600px;
          }
        }
        .tileRow {
          display: flex;
          gap: 4px;
          flex: 1;
          flex-direction: column;
          .secondTile,
          .thirdTile {
            display: flex;
            flex: 1 1 auto;
            height: 50%;
            position: relative;
            z-index: 2;
            overflow: hidden;
            border-left: 2px solid white;

            img.pic {
              width: 100%;
              z-index: 2;
            }
            video {
              width: 100%;
              height: 100%;
            }
            img,
            video {
              object-fit: contain;
            }
            .bgImage {
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: 1;
              width: 100%;
              height: 100%;
              -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
              filter: blur(10px);
              object-fit: cover !important;
            }
          }
          .thirdTile {
            border-top: solid 1px white;
          }
        }
      }

      /* Single Portait */
      &.singlePortait {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        &.ads {
          height: auto;
          cursor: pointer;
          padding: 0 6px 9px 6px;
        }
        video,
        img {
          object-fit: contain;
        }
        .firstTile {
          display: flex;
          flex: 1;
          height: 100%;
          width: 100%;
          position: relative;
          justify-content: center;
          overflow: hidden;
          img:first-child {
            max-height: 500px;
          }
          img {
            position: relative;
            z-index: 2;
            object-fit: fill !important;
          }
          img.bgImage {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 0;
            width: 100%;
            height: 100%;
            -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
            filter: blur(10px);
            object-fit: cover !important;
          }
        }
      }
    }
  }

  .postReactList {
    display: block;
    width: 204px;
    position: absolute;
    top: 30px;
    z-index: 5;
    ul {
      margin: 0px;
      padding: 0px;
      background: var(--border-color);
      padding: 6px 12px;
      position: relative;
      li {
        display: block;
        font-size: 12px;
        clear: both;
        padding: 6px 0;
        overflow: hidden;

        i {
          width: 18px;
          height: 18px;
          float: left;
          display: block;
          &.like {
            background: url(/img/thumbs-active-small.svg) no-repeat;
          }
          &.interest {
            background: url(/img/interest-small.svg) no-repeat;
          }
          &.laugh {
            background: url(/img/laugh-small.svg) no-repeat;
          }
        }
        span {
          display: block;
          float: left;
        }
      }
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: var(--border-color);
        position: absolute;
        left: 17px;
        padding: 0px;
        top: -5px;
        transform: rotate(45deg);
      }
    }
  }
  /* Mobile Portait */
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .actionButtons {
      button {
        span.xs-hide {
          display: none;
        }
      }
    }
    .postForm {
      header {
        .dropDown {
          min-width: 210px;
        }
        div.sharing {
          flex-wrap: wrap;
          flex-direction: column;
        }
      }
      .cont {
        padding-left: 0px;
      }
      footer.footer {
        margin-left: 0px;
        flex-direction: column;
        ul.rightNav,
        ul.leftNav {
          width: 100%;
        }

        ul.rightNav {
          width: 100%;
          justify-content: flex-start;
          margin-top: 8px;
          flex-direction: column;

          li {
            padding-left: 0px;
            position: relative;
            &:first-child {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: center;
              padding: 10px 0;
            }
            button.postNow {
              width: 100%;
            }
            button.link {
              position: absolute;
              top: -35px;
              left: 105px;
            }
          }
        }
      }
    }
  }
}
.showNextPage {
  border: none;
  background: 0px;
  padding: 0px;
  margin: 0px;
  position: relative;
  top: -13px;
  right: 20px;
  background: white;
  border-radius: 40px;
  z-index: 20;
  float: right;
  img {
    border-radius: 40px;
  }
}

#newsfeed {
  .schedulePost {
    padding: 0 5px;
    .post {
      box-shadow: none;
    }
    .date {
      margin: 0px;
      background: var(--light-gray-color);
      padding: 12px;
      font-size: 12px;
      text-align: center;
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 319px) {
  #newsfeed {
    .poll {
      .pollResponse {
        .selectbox {
          width: 200px;
        }
      }
    }

    .postForm {
      header {
        .sharing {
          display: flex;
          flex-direction: column;
          .dropDown {
            min-width: 100%;
            .txt {
              max-width: 200px;
            }
          }
        }
      }
      .cont {
        padding-left: 5px;
        input.postTitle {
          font-size: 14px;
        }
      }
      .footer {
        margin-left: 5px;
        flex-direction: column;
        position: relative;
        .leftNav {
          width: 100%;
          li {
            margin-bottom: 5px;
            padding-right: 5px;
          }
        }
        .rightNav {
          width: 100%;
          position: relative;
          li:first-child {
            position: absolute;
            top: -5px;
            left: 110px;
          }
          li:last-child {
            padding: 0px;
            width: 100%;
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
/* CK Editor */
.ck-content {
  ul {
    font-size: 14px;
  }
}
.ck-file-dialog-button {
  display: none;
}

.msg.danger {
  color: #ff0000;
  font-size: 12px;
  margin: 10px 0 0 50px;
}

.alert-dismissible .close {
  z-index: 12;
}

.video-object-fit-contain {
  object-fit: contain;
  iframe {
    width: 100%;
    height: 100%;
    min-height: 412px;
  }
}

@media screen and (max-width: 590px) {
  #newsfeed {
    .post {
      .gallery {
        &.tile {
          .firstTile {
            img.pic {
              border-bottom: solid 1px white;
              width: 100%;
              height: 100%;
              max-height: 300px;
              object-fit: cover;
            }
          }
          .tileRow {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            max-height: 300px;

            img,
            video {
              object-fit: contain;
            }
            video {
              width: 100%;
              height: 100%;
            }
            .thirdTile {
              border-left: solid 1px white;
            }
            .secondTile,
            .thirdTile {
              position: relative;
              object-fit: contain;
              display: flex;
              flex: 1;
              .pic {
                width: 100%;
              }
            }
          }
        }
      }
      .cont {
        &.fixedHeight {
          max-height: 200px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #newsfeed {
    .post {
      .gallery {
        &.tile {
          .firstTile {
            width: 100%;
            height: auto;
            max-height: 400px;
            position: relative;
            overflow: hidden;
            z-index: 2;
            border-bottom: 4px solid white;

            img.pic {
              width: 100%;
              height: 100%;
              max-height: 400px;
              object-fit: contain;
              z-index: 2;
            }

            .bgImg {
              position: absolute;
              top: 0;
              left: 0;
              -webkit-filter: blur(10px);
              filter: blur(10px);
              object-fit: cover !important;
              max-height: 400px;
              width: 100%;
              height: 100%;
              z-index: 1;
            }
          }
          .tileRow {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            max-height: 400px;

            img,
            video {
              object-fit: contain;
            }
            video {
              width: 100%;
              height: 100%;
            }
            .thirdTile {
              border-left: solid 4px white;
            }
            .secondTile,
            .thirdTile {
              position: relative;
              display: flex;
              flex: 1;
              overflow: hidden;
              z-index: 2;

              .pic {
                width: 100%;
                object-fit: contain;
                z-index: 2;
              }
              .bgImg {
                position: absolute;
                top: 0;
                left: 0;
                -webkit-filter: blur(10px);
                filter: blur(10px);
                object-fit: cover !important;
                width: 100%;
                height: 100%;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 2000px) {
  #newsfeed {
    .post {
      .gallery {
        &.tile {
          .firstTile {
            width: 100%;
            height: auto;
            max-height: 550px;
            position: relative;
            overflow: hidden;
            z-index: 2;
            border-bottom: 4px solid white;

            img.pic {
              width: 100%;
              height: 100%;
              max-height: 550px;
              object-fit: contain;
              z-index: 2;
            }

            .bgImg {
              position: absolute;
              top: 0;
              left: 0;
              -webkit-filter: blur(10px);
              filter: blur(10px);
              object-fit: cover !important;
              max-height: 550px;
              width: 100%;
              height: 100%;
              z-index: 1;
            }
          }
          .tileRow {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            max-height: 550px;

            img,
            video {
              object-fit: contain;
            }
            video {
              width: 100%;
              height: 100%;
            }
            .thirdTile {
              border-left: solid 4px white;
            }
            .secondTile,
            .thirdTile {
              position: relative;
              display: flex;
              flex: 1;
              overflow: hidden;
              z-index: 2;

              .pic {
                width: 100%;
                object-fit: contain;
                z-index: 2;
              }
              .bgImg {
                position: absolute;
                top: 0;
                left: 0;
                -webkit-filter: blur(10px);
                filter: blur(10px);
                object-fit: cover !important;
                width: 100%;
                height: 100%;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }
}
